.contentType2.highlighted {
  background: #82abf7;
  border: 1px solid #3276f5;
  border-radius: 20px;
  padding: 8px;
}

.contentType2.not-highlighted {
  background: none;
  border: none;
  border-radius: 0x;
  padding: 0px;
}

.contentType2-listening-test-mode.selected {
  background: #82abf7;
  border: 1px solid #82abf7;
}

.contentType2-listening-test-mode.not-selected {
  background: transparent;
  border: 1px solid #82abf7;
}

.contentType2-listening-test-mode.selected.correct {
  background: #d0f8cf;
  border: 1px solid #049f00;
}

.contentType2-listening-test-mode.selected.not-correct {
  background: #f3c9d2;
  border: 1px solid #ed1c24;
}

.contentType2-listening-test-mode.not-selected.correct {
  background: #d0f8cf;
  border: 1px solid #049f00;
}

