.exam-continue-button {
    font: 18px Poppins-Bold;
    border-radius: 24px;
    padding: 10px;
    height: auto;
    background: #4252be;
    color: white;
    border: none;
    margin-top: 1.2em;
  
    @media only screen and (min-width: 768px) {
      padding: 12px;
      font-size: 25px;
    }
  }