@import "~antd/dist/antd.less";

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  color: white;
  text-align: center;
}

.bottom-footer {
  @media only screen and (min-height: 900px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    text-align: center;
  }
}

.page-container {
  position: relative;
  min-height: 82vh;
}

.content-wrap {
  padding-bottom: 1.5rem;    /* Footer height */
}

.footer-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1.5rem;            /* Footer height */
}


.sound-button {
  cursor: "pointer";

  @media only screen and (min-width: 100px) {
    width: 20%;
  }

  @media only screen and (min-width: 200px) {
    width: 45px;
    height: 45px;
  }

  @media only screen and (min-width: 768px) {
    width: 65px;
    height: 65px;
  }
}

.back-button {
  cursor: "pointer";
  margin-bottom: 50px;

  @media only screen and (min-width: 220px) {
    margin-right: 8px;
    width: 40%;
  }
  @media only screen and (min-width: 576px) {
    margin-right: 32px;
    width: 25px;
  }
}

.sound-nav-bar {
  //   position: -webkit-sticky;
  padding-top: 20px;
  position: sticky;
  top: 0;
  background-color: white;
  width: 100%;
  z-index: 1;
}

@primary-color: #4252BE;