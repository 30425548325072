/*defining flex display in css not working for some reason, only works via inline*/
.dropzone-container {
  display: flex;
}

.dropzone {
  border: 3px dashed #eee;
  width: 200px;
  padding-top: 30px;
  margin: auto auto;
}

.dropzoneActive {
  border: 3px dashed black;
}
