.contentType8-quiz-test-mode.selected {
  background: #82abf7;
  border: 1px solid #82abf7;
}

.contentType8-quiz-test-mode.not-selected {
  background: transparent;
  border: 1px solid #82abf7;
}

.contentType8-quiz-test-mode.selected.correct {
  background: #d0f8cf;
  border: 1px solid #049f00;
}

.contentType8-quiz-test-mode.selected.not-correct {
  background: #f3c9d2;
  border: 1px solid #ed1c24;
}

.contentType8-quiz-test-mode.not-selected.correct {
  background: #d0f8cf;
  border: 1px solid #049f00;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  color: white;
  text-align: center;
}
