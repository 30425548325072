@font-face {
  font-family: "Poppins-Bold";
  src: url(./fonts/Poppins-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Medium";
  src: url(./fonts/Poppins-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url(./fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url(./fonts/Poppins-SemiBold.ttf) format("truetype");
}
div.ant-modal-content {
  border-radius: 8px 8px 8px 8px;
}

button.ant-modal-close {
  top: 6px;
  right: 7px;
}
div.ant-modal-body {
  padding: 24px 0px 0px 0px;
}
