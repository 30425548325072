.user-dashboard-nav-bar-ul-container {
  list-style-type: none;
  padding: 0;
  border-bottom: 4px solid #f1f1f1;
  margin: 16px 0px;
  align-items: center;
  justify-content: center;
}

ul.user-dashboard-nav-bar-ul-container {
  display: flex;
}
ul.user-dashboard-nav-bar-ul-container li {
  text-align: center;
  display: block;
}

.user-dashboard-nav-bar-ul-container li a {
  cursor: pointer;
  padding: 0 80px;
  display: block;
}
