.course-page-text-wrapper {
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.14);
  border-radius: 24px;
  font-family: Poppins-Regular;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
}

.course-page-text-wrapper-small {
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.14);
  border-radius: 24px;
  font-family: Poppins-Regular;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}
