.home-page-popover-container-wrapper .ant-popover-inner {
  border-radius: 8px;
}

.home-page-logo {
  height: 60px;

  @media only screen and (min-width: 568px) {
    height: 80px;
  }
}

.home-page-topbar-text {
  font-size: 16px;

  @media only screen and (min-width: 568px) {
    font-size: 20px;
  }
}

.home-page-top-section-image {
  height: 300px;
  width: 100%;
  object-fit: cover;

  @media only screen and (min-width: 768px) {
    height: 400px;
  }

  @media only screen and (min-width: 992px) {
    height: 650px;
  }
}

.home-page-row1-side {
  padding: 25px;

  @media only screen and (min-width: 400px) {
    padding: 40px;
  }

  @media only screen and (min-width: 568px) {
    padding: 80px;
  }

  @media only screen and (min-width: 992px) {
    padding: 60px 0px;
  }
}

.home-page-row2-get-started {
  text-align: center;
  margin-top: 2.4em;

  @media only screen and (min-width: 992px) {
    padding-right: 120px;
  }
}

.home-page-get-started-button {
  border-radius: 24px;
  padding: 12px 40px;
  height: fit-content;
  width: fit-content;
  font: 25px Poppins-Regular;
  white-space: normal;
  overflow-wrap: break-word;

  @media only screen and (min-width: 992px) {
    padding: 12px 60px;
  }
}

.home-page-rows {
  padding: 80px 40px;
  text-align: center;
  @media only screen and (min-width: 992px) {
    padding: 128px;
    text-align: start;
  }
}

.home-page-row2-title {
  font: 60px Poppins-Bold;
  color: #4252be;
  margin-top: 1.2em;
  margin-top: 45px;

  // @media only screen and (min-width: 768px) {
  //   font: 45px Poppins-Bold;
  // }

  // @media only screen and (min-width: 992px) {
  //   margin-top: 20px;
  // }
}

.home-page-row2-description {
  font: 18px Poppins-Regular;
  color: black;
  padding: 60px 30px;

  @media only screen and (min-width: 768px) {
    font-size: 28px;
    padding: 0px 60px 60px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 35px;
    padding: 0px 120px 60px;
  }
}

.home-page-row3-title {
  font: 30px Poppins-Bold;
  color: #423F3F;
  margin-top: 3.6em;

  @media only screen and (min-width: 768px) {
    margin-top: 0px;
  }

  @media only screen and (min-width: 1200px) {
    margin-top: 2.4em;
    font-size: 45px;
  }
}

.home-page-row3-description {
  font: 18px Poppins-Regular;
  color: #423F3F;
  margin-top: 1.2em;

  @media only screen and (min-width: 1200px) {
    font-size: 20px
  }
}

.home-page-store-text-col {
  text-align: center;
  padding: 20px;

  @media only screen and (min-width: 768px) {
    text-align: start;
    padding: 0px 50px;
  }

  @media only screen and (min-width: 1200px) {
    padding: 0px 100px 100px;
  }
}

.home-page-mobile-image-col {
  padding: 0px 20px;
  text-align: center;

  @media only screen and (min-width: 768px) {
    padding: 0px 100px;
  }

  @media only screen and (min-width: 768px) {
    padding: 0px;
  }
}

.home-page-row-store {
  padding: 20px 40px;
  text-align: center;
  @media only screen and (min-width: 992px) {
    padding: 20px 60px;
    text-align: start;
  }

  @media only screen and (min-width: 1200px) {
    padding: 60px 128px;
    text-align: start;
  }
}

.home-page-store-image {
  width: 45%;

  @media only screen and (min-width: 768px) {
    width: 85%;
  }
}

.home-page-mobile-image {
  margin-top: 2.4em;
  width: 260px;

  @media only screen and (min-width: 768px) {
    width: 220px;
  }

  @media only screen and (min-width: 1200px) {
    width: 90%;
  }
}

.button-text-white {
  width: 200px;
  height: 60px;
  background-color: black;
  border: 3px solid black;
  border-radius: 15px;
  box-shadow: 0px 10px 30px #0d111430;
  font: 18px Poppins-Bold;
  color: white;
  margin-top: 2.4em;

  @media only screen and (min-width: 768px) {
    margin-top: 1.2em;
  }

  @media only screen and (min-width: 1200px) {
    margin-top: 2.4em;
  }
}

.button-text-white:hover,
.button-text-white:focus {
  color: black;
  background: white;
  border-color: unset;
}


.sign-up-gender-select-new {
  .ant-select-selector {
    font: 18px Poppins-Light;
    padding: 25px 30px !important;
    align-items: center;
    text-align: start;
    border-radius: 5px !important;
    border-color: #ddedf9 !important;
  }
  

  .ant-select-arrow {
    right: 30px;
  }

  &.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
    background-color: lightgray !important;
  }

  &.ant-select-item.ant-select-item-option {
    padding-left: 30px;
    padding-right: 30px;
    text-align: start;
  }
}
@primary-color: #4252BE;